import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { ReportPreviewComponent } from 'src/app/modules/pages/nexus-pages/reporting-report-template/components/preview/preview.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/shell/shell.module').then(m => m.ShellModule) },

  // LAZY LOADING CHILD COMPONENTS: https://angular.io/guide/lazy-loading-ngmodules
  { path: 'reporting-report-preview', loadChildren: () => import('./modules/pages/nexus-pages/reporting-report-template/preview/preview.module').then(m => m.ReportPreviewModule) },
  { path: 'reporting-widget-preview', loadChildren: () => import('./modules/pages/nexus-pages/reporting-composite-widget/preview/preview.module').then(m => m.WidgetPreviewModule) },
  { path: 'modularity-assessment-preview', loadChildren: () => import('./modules/pages/nexus-pages/modularity-assessment/preview/preview.module').then(m => m.AssessmentPreviewModule) },  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled' // ENABLES SCROLLING TO ANCHOR TAGS
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
